var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.pois,"items-per-page":10},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getPoiMapName")(item))+" ")]}},{key:"item.amenity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("localizeAmenities")(item.amenity))+" ")]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('poi-detail-dialog',{attrs:{"poi":item,"hideLabel":true,"showMap":true}})]}},{key:"item.visited",fn:function(ref){
var item = ref.item;
return [_c('poi-visit-button',{attrs:{"poiId":item.id,"hideLabel":true}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }