<template>
  <v-data-table
    :headers="headers"
    :items="pois"
    :items-per-page="10"
    class="elevation-1"
  >
    <template v-slot:item.name="{ item }">
      {{ item | getPoiMapName }}
    </template>
    <template v-slot:item.amenity="{ item }">
      {{ item.amenity | localizeAmenities }}
    </template>
    <template v-slot:item.details="{ item }">
      <poi-detail-dialog :poi="item" :hideLabel="true" :showMap="true" />
    </template>
    <template v-slot:item.visited="{ item }">
      <poi-visit-button :poiId="item.id" :hideLabel="true" />
    </template>
  </v-data-table>

  <!-- <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">Name</th>
          <th class="text-left">Typ</th>
          <th class="text-left">Details</th>
          <th class="text-left">Besucht</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in pois" :key="item.name">
          <td>{{ item.name }}</td>
          <td>{{ item.amenity | localizeAmenities }}</td>
          <td><poi-detail-dialog :poi="item" :hideLabel="true" /></td>
          <td><poi-visit-button :poiId="item.id" :hideLabel="true" /></td>
        </tr>
      </tbody>
    </template>
  </v-simple-table> -->
</template>
<script>
import PoiDetailDialog from "../PoiDetailDialog.vue";
import PoiVisitButton from "./PoiVisitButton.vue";

export default {
  components: { PoiDetailDialog, PoiVisitButton },
  name: "PoiTable",
  props: ["pois"],
  data() {
    return {
      headers: [
        { text: "Name", align: "start", sortable: false, value: "name" },
        { text: "Typ", sortable: false, value: "amenity" },
        { text: "Details", value: "details", sortable: false },
        { text: "Besucht", value: "visited", sortable: false },
      ],
    };
  },
};
</script>
